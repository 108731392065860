.wrapper {
  padding-bottom: 32px;
  margin-bottom: 32px;
  font-size: 14px;
  border-bottom: 1px solid var(--color-bg-stripe);
}

.create {
  min-width: 230px;
}

.hint {
  margin-bottom: 12px;
}

@media (--bp-mobile-up) {
  .field {
    width: 100%;
    max-width: 415px;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}
