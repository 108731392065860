.root {
  position: relative;
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  text-decoration: none;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: color;
  transition-timing-function: ease-out;

  @mixin focus-border;
}

.theme_brand {
  @mixin color-brand-states;

  &.disabled {
    color: var(--color-text-brand-disabled);
  }
}

.theme_primary {
  @mixin color-primary-states;
}

.theme_secondary {
  @mixin color-secondary-states;
}

.theme_alert {
  color: var(--color-text-alert);

  :--focus,
  &:hover {
    color: var(--color-text-alert-hover);
  }
}

.disabled {
  color: var(--color-text-disabled);
  pointer-events: none;
  cursor: default;
}

.text {
  position: relative;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  cursor: inherit;
  color: currentColor;

  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  .content.onlyIcon & {
    @mixin visually-hidden;
  }
}

.icon {
  display: inline-block;
  line-height: 0;

  &_position_right {
    order: 1;
  }
}

.icon + .text {
  margin-left: 5px;
}

.icon_position_right + .text {
  margin-left: 0;
  margin-right: 5px;
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  line-height: inherit;

  &.onlyIcon {
    width: 24px;
    height: 24px;
    justify-content: center;
  }
}
