.colorPicker {
  display: block;
  position: relative;
}

.button {
  display: block;
  width: 48px;
  height: 48px;
  border: 1px solid var(--color-bg-stripe);

  &:hover {
    border-color: var(--color-bg-stripe-hover);
  }

  @mixin focus-border var(--color-bg-stripe-hover);
}

.dropdown {
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;
  margin-top: 8px;
}
