@font-face {
  font-family: 'Open Sans';
  src:
    url('../fonts/OpenSans/opensans-regular.woff2') format('woff2'),
    url('../fonts/OpenSans/opensans-regular.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('../fonts/OpenSans/opensans-light.woff2') format('woff2'),
    url('../fonts/OpenSans/opensans-light.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('../fonts/OpenSans/opensans-semibold.woff2') format('woff2'),
    url('../fonts/OpenSans/opensans-semibold.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('../fonts/OpenSans/opensans-bold.woff2') format('woff2'),
    url('../fonts/OpenSans/opensans-bold.woff') format('woff');
  font-weight: 700;
}
