.container {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.menu {
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;
  min-width: 105px;
}
