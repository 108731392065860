.root {
  display: inline-block;
  line-height: 0;
  color: inherit;
  fill: currentColor;
  cursor: inherit;
  pointer-events: inherit;
}

.noFill {
  fill: none;
}
