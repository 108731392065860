.section {
  display: block;
  font-size: 14px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  margin-right: 16px;
  width: 64px;
  height: 64px;
  border-radius: 100%;
}

.brand {
  display: flex;
  align-items: center;
  color: var(--color-text-secondary);
}
