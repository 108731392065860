.submit {
  display: block;
  margin-bottom: 8px;
}

.cancelButton {
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: var(--color-text-alert);
}
