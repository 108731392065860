.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  display: block;
  margin-bottom: 20px;
  color: var(--color-text-primary);
}

.message {
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
  color: var(--color-text-secondary);
}
