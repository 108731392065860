.drawerBody {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  overflow: auto;
  transform: translate3d(100%, 0, 0);
  transition: 0.2s transform ease-out;
  z-index: 1;

  @media (--bp-mobile) {
    width: 100%;
  }

  @media (--bp-mobile-up) {
    width: 448px;
  }
}

.drawerHeader {
  position: sticky;
  top: 0;
  z-index: 1;
}

.drawerContent {
  @media (--bp-mobile) {
    padding: 16px;
    width: 100%;
  }

  @media (--bp-mobile-up) {
    padding: 0 32px 32px 32px;
  }
}

.drawerOverlay {
  display: none;
}

.drawer_scrolled .drawerHeader {
  box-shadow: var(--shadow-light);
  transition: 0.2s box-shadow ease-out;
}

.drawer_open,
.drawer_open .drawerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.drawer_open .drawerOverlay {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
}

.drawer_open .drawerBody {
  background-color: var(--color-bg-default);
  box-shadow: var(--shadow-modal);
  transform: translate3d(0, 0, 0);
}
