.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.logo {
  display: flex;
  padding: 24px;
  padding-bottom: 16px;
  flex-shrink: 0;

  @media (--bp-mobile-up) {
    justify-content: center;
  }
}

.nav {
  display: block;
  flex-grow: 1;
  flex-shrink: 0;
}

.menu {
  border: none;
}
