.root {
  width: 24px;
  height: 24px;
  display: block;
  font-size: 0;
  line-height: 0;
  border: 1px solid var(--color-bg-stripe);
  color: var(--color-text-secondary);
  background-color: var(--color-bg-default);
  cursor: pointer;
  text-align: center;

  @mixin color-secondary-states;

  &:hover {
    background-color: var(--color-bg-light);
    border-color: var(--color-bg-stripe-hover);
  }

  @mixin focus-border var(--color-bg-stripe-hover) {
    background-color: var(--color-bg-light);
  }

  &:disabled {
    background-color: var(--color-bg-component-disabled);
    color: var(--color-text-disabled);
    pointer-events: none;
  }
}

.view {
  &_primary {
    @mixin color-primary-states;
  }

  &_round {
    border-radius: 100%;
  }

  &_primary,
  &_secondary {
    background: none !important;
    border: none;

    @mixin focus-border;
  }
}

.size {
  &_small {
    width: 16px;
    height: 16px;
  }
}
