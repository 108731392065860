.worklogsGroup {
  margin-bottom: 32px;
}

.worklogsGroupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.worklogsGroupDate {
  padding: 7px 16px;
  background-color: var(--color-bg-component);
  border: 1px solid var(--color-bg-stripe);
  border-radius: 15px;
  color: var(--color-text-secondary);
  font-size: 14px;
  line-height: 1;
}

.worklogsGroupTotal {
  font-size: 14px;
  font-weight: bold;
  color: var(--color-text-primary);

  @media (--bp-mobile) {
    padding-right: 16px;
  }

  @media (--bp-mobile-up) {
    padding-right: 144px;
  }
}

.loadMore {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
}

.worklogsGroupChildren {
  display: table;
  width: 100%;
}
