.checkboxMenuButton {
  position: relative;
  padding: 5px 20px 5px 5px;
  border: 1px solid transparent;

  &::after {
    display: none;
    content: '';
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 1.25px;
    height: 0;
    width: 0;
    /* stylelint-disable */
    border-color: #999 transparent transparent;
    /* stylelint-enable */
    border-style: solid;
    border-width: 5px 5px 2.5px;
  }

  @media (--bp-mobile) {
    padding: 7px 32px 7px 7px;
    display: flex;
    align-items: center;

    &_open {
      border-color: var(--colorBorderHoverCheckBox);
      background-color: var(--colorHoverCheckBox);

      html[data-theme='dark'] & {
        background-color: var(--colorHoverCheckBox_darkTheme);
        border-color: var(--colorBorderHoverCheckBox_darkTheme);
      }
    }

    &::after {
      display: block;
      right: 10px;
    }
  }

  @media (--bp-mobile-up) {
    &:hover,
    &_open {
      background-color: var(--colorHoverCheckBox);
      border-color: var(--colorBorderHoverCheckBox);

      html[data-theme='dark'] & {
        background-color: var(--colorHoverCheckBox_darkTheme);
        border-color: var(--colorBorderHoverCheckBox_darkTheme);
      }
    }

    &_open {
      /* stylelint-disable */
      box-shadow: inset 0 0 3px 2px var(--softShadow);
      /* stylelint-enable */
      border-color: var(--colorBorderActiveCheckBox);

      html[data-theme='dark'] & {
        border-color: var(--colorBorderActiveCheckBox_darkTheme);
      }
    }

    &_open::after,
    &:hover::after {
      display: block;
    }
  }
}

.checkboxMenuMenu {
  position: absolute;
  z-index: 1;
}
