.options {
  display: block;
  margin-bottom: 32px;
}

.table {
  display: block;
  transition: 0.2s opacity;
}

.table_loading {
  opacity: 0.5;
}
