.root {
  position: relative;
}

.label {
  position: absolute;
  top: 16px;
  left: 16px;
  right: 16px;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-text-secondary);
  cursor: text;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &_with_icon {
    right: 36px;
  }
}

.field {
  padding-top: 16px;
  padding-left: 15px;
  padding-right: 15px;
  height: 48px;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-text-primary);
  background-color: var(--color-bg-component);
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-bg-stripe);
  border-radius: 0;
  -webkit-appearance: none;

  @media (--bp-mobile) {
    font-size: 16px;
  }

  &:disabled {
    color: var(--color-text-secondary);
    background-color: var(--color-bg-component-disabled);
  }

  &:global(.focus-ring),
  &:focus {
    outline: none;
    text-overflow: clip;
    border-color: var(--color-bg-brand);

    &::-ms-clear,
    &::-ms-reveal {
      display: none;
    }
  }

  &_filled + .label,
  &:global(.focus-ring) + .label,
  &:-webkit-autofill + .label,
  &:focus + .label {
    top: 8px;
    font-size: 10px;
    line-height: 12px;
  }

  @-moz-document url-prefix() {
    &_filled + .label,
    &:global(.focus-ring) + .label,
    &:focus + .label {
      top: 8px;
      font-size: 10px;
      line-height: 12px;
    }
  }

  @media all and (-ms-high-contrast: none) {
    *::-ms-backdrop,
    &_filled + .label,
    &:global(.focus-ring) + .label,
    &:focus + .label {
      top: 8px;
      font-size: 10px;
      line-height: 12px;
    }
  }

  &_error {
    border-color: var(--color-bg-alert) !important;
  }

  &_success {
    border-color: var(--color-bg-success) !important;
  }

  &_tip {
    width: calc(100% - 208px);
  }
}

.tip,
.hint {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-secondary);
}

.tip {
  margin-left: 8px;
  display: inline-block;
  width: 200px;
  vertical-align: top;

  &_error {
    color: var(--color-text-alert);
  }
}

.hint {
  margin-top: 4px;
  width: 100%;

  &_short {
    width: calc(100% - 208px);
  }

  &_absolute {
    position: absolute;
  }

  &_success {
    color: var(--color-text-success);
  }

  &_error {
    color: var(--color-text-alert);
  }
}
