.root {
  font-size: inherit;
  white-space: pre-line;
  line-height: inherit;
  cursor: inherit;
}

.theme_primary {
  color: var(--color-text-primary);
}

.theme_secondary {
  color: var(--color-text-secondary);
}

.theme_success {
  color: var(--color-text-success);
}

.theme_alert {
  color: var(--color-text-alert);
}

.size_12 {
  font-size: 12px;
  line-height: 16px;

  &.with_margin {
    margin-bottom: 6px;
  }
}

.size_14 {
  font-size: 14px;
  line-height: 20px;

  &.with_margin {
    margin-bottom: 8px;
  }
}

.size_16 {
  font-size: 16px;
  line-height: 22px;

  &.with_margin {
    margin-bottom: 10px;
  }
}

.size_18 {
  font-size: 18px;
  line-height: 24px;

  &.with_margin {
    margin-bottom: 12px;
  }
}

.size_20 {
  font-size: 20px;
  line-height: 28px;

  &.with_margin {
    margin-bottom: 14px;
  }
}

.size_22 {
  font-size: 22px;
  line-height: 32px;

  &.with_margin {
    margin-bottom: 16px;
  }
}

.size_24 {
  font-size: 24px;
  line-height: 36px;

  &.with_margin {
    margin-bottom: 18px;
  }
}

.size_28 {
  font-size: 28px;
  line-height: 40px;

  &.with_margin {
    margin-bottom: 20px;
  }
}

.size_32 {
  font-size: 32px;
  line-height: 44px;

  &.with_margin {
    margin-bottom: 22px;
  }
}

.size_36 {
  font-size: 36px;
  line-height: 48px;

  &.with_margin {
    margin-bottom: 24px;
  }
}

.mode_ellipsis {
  overflow: hidden;
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align_center {
  text-align: center;
}

.align_right {
  text-align: right;
}

.align_justify {
  text-align: justify;
}
