.itemSection {
  margin-bottom: 16px;
}

.itemSectionTitle {
  font-size: 12px;
  color: var(--color-text-secondary);
  margin-bottom: 4px;
}

.itemTitle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.itemIcon {
  position: absolute;
  left: -32px;
  top: 0;
  width: 16px;
  height: 20px;
  color: var(--color-text-active);
  background-color: var(--color-bg-brand);
}

.itemDates {
  margin-bottom: 4px;
  font-size: 12px;
  color: var(--color-text-secondary);
}

.itemProject {
  display: inline-block;
  vertical-align: bottom;
}

.itemMenu {
  position: absolute;
  right: 0;
  min-width: 104px;
}

.itemClipboard {
  position: relative;
  font-size: 14px;
  font-weight: 300;

  @mixin color-secondary-states;
  @mixin focus-border;

  &_copied {
    color: var(--color-text-success);
  }
}

.clipboardIcon {
  display: inline-block;
  margin-right: 8px;
  vertical-align: -3px;
}
