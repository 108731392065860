.root {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: var(--color-bg-light);
  border: 1px solid var(--color-bg-stripe);
  color: var(--color-text-secondary);
  line-height: 22px;
}
