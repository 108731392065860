.container {
  &_sidebarOpen {
    overflow: hidden;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: var(--color-bg-component);

  .container_sidebarOpen & {
    transform: translateX(0);
    box-shadow: var(--shadow-light);
  }

  @media (--bp-mobile) {
    width: 260px;
    transform: translateX(-100%);
    transition: transform 200ms;
    z-index: 3;
  }

  @media (--bp-mobile-up) {
    width: var(--sidebar-width-desktop);
    border-right: 1px solid var(--color-bg-stripe);
  }
}

.sidebarClose {
  position: absolute;
  top: 16px;
  right: 16px;

  .container:not(.container_sidebarOpen) & {
    display: none;
  }
}

.sidebarOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: var(--color-bg-backdrop);

  .container:not(.container_sidebarOpen) & {
    display: none;
  }
}

.main {
  position: relative;

  @media (--bp-mobile) {
    padding-top: 56px;
    transition: transform 200ms;
  }

  @media (--bp-mobile-up) {
    overflow-x: auto;
    min-height: 100vh;
    margin-left: var(--sidebar-width-desktop);
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  height: 56px;
  align-items: center;
  border-bottom: 1px solid var(--color-bg-stripe);
  background-color: var(--color-bg-default);
  transition: transform 200ms;

  @media (--bp-mobile-up) {
    display: none;
  }
}

.headerMenuButton {
  position: absolute;
  top: 16px;
  left: 16px;
}

.headerTitle {
  width: 100%;
  padding: 0 56px;
  font-size: 24px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content {
  padding: 16px;

  @media (--bp-mobile-up) {
    padding: 48px;
  }
}

.alert {
  position: fixed;
  top: 0;
  left: var(--sidebar-width-desktop);
  right: 0;

  @media (--bp-mobile) {
    display: none;
  }
}
