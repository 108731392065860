@media (--bp-mobile) {
  .report {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -16px;
    display: flex;
    flex-direction: column;
  }

  .header {
    display: none;
  }

  .nav {
    order: -1;
    padding: 16px 16px 0;
    margin-bottom: -16px;
  }

  .content,
  .header_mobile,
  .filters,
  .total,
  .chart {
    transition: 500ms ease-out;
  }

  .report_anim {
    .content,
    .headerMobile,
    .filters,
    .total,
    .chart {
      transition: none;
      opacity: 0;
    }
  }
}

@media (--bp-mobile-up) {
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }

  .headerMobile {
    display: none;
  }

  .title {
    margin-right: 8px;
  }

  .filters {
    margin-bottom: 24px;
  }

  .total {
    margin-bottom: 40px;
  }

  .chart {
    margin-bottom: 40px;
  }
}
