@media (--bp-mobile) {
  .toggler {
    padding-top: 15px;
  }
}

@media (--bp-mobile-up) {
  .action:not(:last-child) {
    margin-right: 16px;
  }

  .nav {
    min-height: 62px;
  }

  .archive {
    position: relative;
    right: initial;
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .search {
    flex-basis: 432px;
    margin-right: 20px;
  }
}
