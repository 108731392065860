@define-mixin absolute-border $border-color: currentColor, $offset: -3px {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: $offset;
    right: $offset;
    bottom: $offset;
    left: $offset;
    border: 1px solid $border-color;
    border-radius: inherit;
  }
}

@define-mixin focus-border $border-color, $offset {
  :--focus {
    @mixin absolute-border $border-color, $offset;
    outline: none;
    @mixin-content;
  }
}

@define-mixin color-brand-states {
  color: var(--color-text-brand);

  :--focus,
  &:hover {
    color: var(--color-text-brand-hover);
  }

  &:active {
    color: var(--color-text-brand-active);
  }
}

@define-mixin color-primary-states {
  color: var(--color-text-primary);

  :--focus,
  &:hover {
    color: var(--color-text-brand-hover);
  }

  &:active {
    color: var(--color-text-brand-active);
  }
}

@define-mixin color-secondary-states {
  color: var(--color-text-secondary);

  :--focus,
  &:hover {
    color: var(--color-text-primary);
  }

  &:active {
    color: var(--color-text-brand-active);
  }
}

@define-mixin visually-hidden {
  position: absolute;
  margin: 0;
  overflow: visible;
  height: 0;
  width: 0;
  border: 0;
  clip: rect(0 0 0 0);
}
