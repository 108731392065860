.fieldWrapper {
  display: flex;
}

.fieldName {
  flex-grow: 1;
}

.fieldColor {
  margin-left: 16px;
}
