@media (--bp-mobile) {
  .row {
    flex-direction: column;
  }

  .cell {
    display: flex;
  }

  .cell:not(:first-child) {
    margin-top: 10px;
  }

  .columnLabel {
    flex-shrink: 0;
    display: inline-block;
    width: 90px;
    margin-right: 8px;
    color: var(--color-text-secondary);
  }

  .cellNameActions {
    order: -1;
    align-self: flex-end;
    margin-top: 0;
    margin-bottom: -20px;
  }

  .cellNamePosition {
    padding-right: 30px;
  }
}

@media (--bp-mobile-up) {
  .cell {
    margin-right: 40px;
  }

  .cellNamePosition {
    min-width: 300px;
    width: auto;
  }

  .cellNameRate,
  .cellNameDateFrom,
  .cellNameDateTo {
    width: 100px;
  }

  .cellNameActions {
    margin-left: auto;
    margin-right: 0;
  }

  .columnLabel {
    display: none;
  }
}
