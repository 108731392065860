.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 100%;
  cursor: pointer;

  &_color_default {
    @mixin color-secondary-states;
    border: 1px solid var(--color-bg-stripe);
    background-color: var(--color-bg-component);

    &:hover {
      background-color: var(--color-bg-light);
      border-color: var(--color-bg-stripe-hover);
    }

    @mixin focus-border var(--color-bg-stripe-hover) {
      background-color: var(--color-bg-light);
    }
  }

  &_color_primary {
    --timer-action-bg: var(--color-bg-brand);
    --timer-action-bg-hover: var(--color-bg-brand-hover);
    --timer-action-bg-active: var(--color-bg-brand-active);
  }

  &_color_ok {
    --timer-action-bg: var(--color-bg-success);
    --timer-action-bg-hover: var(--color-bg-success-hover);
    --timer-action-bg-active: var(--color-bg-success-hover);
  }

  &_color_warn {
    --timer-action-bg: var(--color-bg-alert);
    --timer-action-bg-hover: var(--color-bg-alert-hover);
    --timer-action-bg-active: var(--color-bg-alert-hover);
  }

  &_color_primary,
  &_color_ok,
  &_color_warn {
    background-color: var(--timer-action-bg);
    color: var(--color-text-active);

    :--focus,
    &:hover {
      background-color: var(--timer-action-bg-hover);
    }

    &:active {
      background-color: var(--timer-action-bg-active);
    }

    @mixin focus-border var(--timer-action-bg-hover), -2px;
  }

  &:disabled {
    background-color: var(--color-bg-component-disabled);
    color: var(--color-text-disabled);
    cursor: default;
    pointer-events: none;
  }
}
