.column {
  font-size: 14px;
}

.columnProject {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-text-secondary);
}

.columnDate {
  font-size: 12px;
  color: var(--color-text-secondary);
}

.columnDuration {
  text-align: right;
}

.descriptionWrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.expand {
  width: 24px;
  min-width: 24px;
  height: 24px;
  padding: 4px;
  margin-right: 8px;
}

.issue {
  margin-bottom: 2px;
  font-size: 12px;
  color: var(--color-text-secondary);
}

.description_empty {
  font-size: 12px;
  color: var(--color-text-disabled);
}

.actions {
  display: flex;
  justify-content: center;
}

.action {
  position: relative;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.actionDropdown {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
}

.actionDropdownTitle {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 14px;
  color: var(--color-text-secondary);
}

.error {
  margin-right: 8px;
}

.rateLabel {
  margin-right: 8px;
}

.lockLabel,
.approveLabel {
  margin-right: 10px;
}

.lockLabel {
  min-width: 16px;
}

.approveLabel {
  min-width: 20px;
}

@media (--bp-mobile) {
  .row {
    display: grid;
    grid-template-areas:
      'actions actions'
      'project date'
      'description duration';
  }

  .rowGroupChild {
    margin-left: 16px;
  }

  .columnProject {
    margin-bottom: 16px;
    font-size: 12px;
    grid-area: project;
  }

  .columnDate {
    grid-area: date;
    text-align: right;
  }

  .columnDuration {
    grid-area: duration;
  }

  .columnActions {
    grid-area: actions;
    min-width: 90px;
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-end;
  }

  .columnDescription {
    grid-area: description;
  }

  .descriptionContent {
    padding-right: 16px;
  }

  .actions {
    width: 90px;
  }
}

@media (--bp-mobile-up) {
  .columnDescription {
    position: relative;
    width: 40%;
    max-width: 300px;
  }

  .columnProject {
    width: 25%;
    max-width: 200px;
  }

  .columnDate {
    position: relative;
    min-width: 50px;
    white-space: nowrap;
  }

  .columnDuration {
    min-width: 56px;
  }

  .columnActions {
    min-width: 122px;
  }

  .rowGroupChild .descriptionWrapper {
    padding-left: 24px;
  }

  .descriptionContent {
    overflow: hidden;
  }

  .issue {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .actions {
    height: 24px;
  }

  .row:not(:hover) .actions:not(.actions_visible) {
    opacity: 0;
  }

  .error {
    position: absolute;
    margin-right: 16px;
    right: 100%;
  }
}
