@media (--bp-mobile) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .add {
    display: none;
  }

  .description {
    order: 2;
    margin-bottom: 16px;
  }

  .project {
    order: 3;
    margin-bottom: 20px;
  }

  .duration {
    position: relative;
    order: 1;
    margin: -16px;
    margin-bottom: 16px;
  }

  .duration_edit {
    order: 3;
  }

  .durationButton {
    width: 100%;
    padding: 36px 0;
    color: var(--color-text-primary);
    font-size: 48px;
    text-align: center;
  }

  .durationButton_progress {
    background-color: var(--color-bg-brand);
    color: var(--color-text-active);
  }

  .duration_edit .durationTicker {
    visibility: hidden;
  }

  .durationForm {
    position: relative;
    padding: 16px;
  }

  .actions {
    display: flex;
    order: 4;
  }

  .action:not(:last-child) {
    margin-right: 16px;
  }

  .doubleRate {
    position: absolute;
    top: 30px;
    right: calc(50% - 124px);
  }
}

@media (--bp-mobile-up) {
  .wrapper {
    display: flex;
    align-items: center;
    height: 52px;
  }

  .add {
    margin-right: 16px;
  }

  .description {
    flex: 3;
    margin-right: 16px;
  }

  .project {
    flex: 1;
    min-width: 200px;
    margin-right: 16px;
  }

  .duration {
    position: relative;
    display: flex;
    justify-content: center;
    height: 52px;
    min-width: 98px;
    margin-right: 32px;
  }

  .durationButton {
    border-bottom: 1px solid transparent;
    font-size: 24px;
    font-weight: 600;
    line-height: 1;

    @mixin color-primary-states;
    @mixin focus-border;

    &:disabled {
      pointer-events: none;
      color: var(--color-text-disabled);
    }
  }

  .duration_edit {
    margin-right: 0;
    min-width: 250px;
  }

  .doubleRate {
    position: absolute;
    top: 0;
    right: -21px;
  }

  .durationForm {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .actions {
    display: flex;
    position: relative;
  }

  .action + .action {
    margin-left: 16px;
  }
}
