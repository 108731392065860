.root {
  padding-left: 16px;
  padding-right: 16px;
  display: inline-flex;
  align-items: center;
  height: 30px;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition-duration: 0.2s;
  transition-property: background-color, color;
  transition-timing-function: ease-out, ease-out;

  &:disabled,
  &.disabled {
    cursor: default;
    pointer-events: none;
  }
}

.block {
  display: block;
  width: 100%;
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.size_big {
  padding-left: 24px;
  padding-right: 24px;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
}

.size_huge {
  height: 48px;
  padding-right: 32px;
  padding-left: 32px;
  font-size: 16px;
  font-weight: 600;
}

.theme_primary {
  color: var(--color-text-active);
  background-color: var(--color-bg-brand);

  :--focus,
  &:hover {
    background-color: var(--color-bg-brand-hover);
  }

  @mixin focus-border var(--color-bg-brand-hover), -2px;

  &:active {
    background-color: var(--color-bg-brand-active);
  }

  &:disabled,
  &.disabled {
    background-color: var(--color-bg-brand-disabled);
  }
}

.theme_ghost {
  color: var(--color-text-secondary);
  background-color: var(--color-bg-component);
  border: 1px solid var(--color-bg-stripe);

  &:hover {
    background-color: var(--color-bg-light);
    border-color: var(--color-bg-stripe-hover);
  }

  @mixin focus-border var(--color-bg-stripe-hover) {
    background-color: var(--color-bg-light);
  }

  &:active {
    color: var(--color-text-primary);
  }

  &:disabled,
  &.disabled {
    color: var(--color-text-disabled);
    background-color: var(--color-bg-component-disabled);
  }
}
