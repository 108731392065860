.root {
  position: relative;
  display: flex;
  min-height: 68px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 58px;
  padding-right: 58px;
  overflow: hidden;
}

.type {
  &_success {
    background-color: var(--color-bg-success);
  }

  &_warning {
    background-color: var(--color-bg-caution);
  }

  &_error {
    background-color: var(--color-bg-alert);
  }

  &_info {
    background-color: var(--color-bg-brand);
  }
}

.content {
  position: relative;
  text-align: center;
  color: var(--color-text-active);
  font-weight: 300;
  font-size: 18px;
  letter-spacing: 0.5px;
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 48px;
}

.link {
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: underline;
  cursor: pointer;
  color: var(--color-text-active);

  @mixin focus-border;

  :--focus,
  &:hover {
    text-decoration: none;
  }
}
