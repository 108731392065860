@media (--bp-mobile) {
  .container {
    border-bottom: 1px solid var(--color-bg-stripe);
  }

  .content {
    display: none;

    &_open {
      display: block;
      padding: 16px;
      overflow: hidden;
    }
  }

  .button {
    width: 100%;
    font-size: 14px;
    font-weight: 600;

    @mixin color-primary-states;
    @mixin focus-border;
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
  }

  .buttonCaption {
    margin-right: 10px;
  }

  .buttonIcon {
    &_expanded {
      transform: rotate(180deg);
      transform-origin: 50% 50%;
    }
  }
}

@media (--bp-mobile-up) {
  .button {
    display: none;
  }
}
