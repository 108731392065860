.fieldDatetime {
  display: flex;
  margin-right: -16px;
}

.fieldDate,
.fieldTime {
  flex: 1;
  margin-right: 16px;
}
