@media (--bp-mobile) {
  .option {
    margin-bottom: 16px;
  }
}

@media (--bp-mobile-up) {
  .options {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-bottom: -24px;
  }

  .option {
    min-width: 200px;
    margin-right: 24px;
    margin-bottom: 24px;
  }

  .optionSort {
    display: none;
  }

  .export {
    margin-left: auto;
    margin-bottom: 24px;
    margin-top: 48px;
  }
}

@media (min-width: 1228px) {
  .export {
    margin-top: 0;
  }
}
