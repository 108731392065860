.loader {
  display: flex;
  justify-content: center;
  width: 100%;
}

.wrapper {
  display: block;
  padding: 20px;
  line-height: 0;
  color: var(--color-text-brand);
}
