.hint {
  display: inline-block;
  position: relative;
}

.hintTrigger {
  display: block;
  background-color: var(--color-bg-alert);
  color: var(--color-text-active);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 10px;
}

.hintTooltip {
  max-width: 300px;
}

.hintContent {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
