.user {
  display: block;
  padding: 24px;
  background-color: var(--color-bg-secondary);
}

.userTitle {
  margin-bottom: 16px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.userName {
  margin-bottom: 5px;
}

.userWorkspace {
  color: var(--color-text-secondary);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.userMenu {
  position: absolute;
  bottom: 100%;
  left: 0;
  margin-bottom: 5px;
  width: 170px;
}

.userMenuWorkspaces {
  max-height: 264px;
  margin-bottom: -8px;
  overflow-y: auto;
  overflow-x: hidden;
}

.userActions {
  position: relative;
  display: flex;
}

.userAction {
  &:not(:last-child) {
    margin-right: 16px;
  }
}
