.root {
  margin: 0;
  padding: 0;
  display: block;
  line-height: inherit;
  font-weight: inherit;
  font-style: inherit;
  word-wrap: inherit;
}

.color {
  &_brand {
    color: var(--color-text-brand);
  }

  &_brandHover {
    color: var(--color-text-brand-hover);
  }

  &_brandActive {
    color: var(--color-text-brand-active);
  }

  &_brandDisabled {
    color: var(--color-text-brand-disabled);
  }

  &_caution {
    color: var(--color-text-caution);
  }

  &_success {
    color: var(--color-text-success);
  }

  &_successHover {
    color: var(--color-text-success-hover);
  }

  &_alert {
    color: var(--color-text-alert);
  }

  &_alertHover {
    color: var(--color-text-alert-hover);
  }

  &_primary {
    color: var(--color-text-primary);
  }

  &_secondary {
    color: var(--color-text-secondary);
  }

  &_disabled {
    color: var(--color-text-disabled);
  }

  &_active {
    color: var(--color-text-active);
  }
}

.is_thin {
  font-weight: 300;
}

.with_margin {
  margin-top: 1.111em;
  margin-bottom: 0.555em;
}

.is_uppercase {
  text-transform: uppercase;
}

.level_1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;

  &.is_thin {
    font-weight: 300;
  }

  &.with_margin {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}

.level_2 {
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;

  &.is_thin {
    font-weight: 400;
  }

  &.with_margin {
    margin-top: 36px;
    margin-bottom: 20px;
  }
}

.level_3 {
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;

  &.is_thin {
    font-weight: 400;
  }

  &.with_margin {
    margin-top: 32px;
    margin-bottom: 20px;
  }
}

.level_4 {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;

  &.is_thin {
    font-weight: 300;
  }

  &.with_margin {
    margin-top: 28px;
    margin-bottom: 16px;
  }
}

.level_5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;

  &.is_thin {
    font-weight: 400;
  }

  &.with_margin {
    margin-top: 24px;
    margin-bottom: 16px;
  }
}

.level_6 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;

  &.is_thin {
    font-weight: 400;
  }

  &.with_margin {
    margin-top: 20px;
    margin-bottom: 16px;
  }
}

.align_center {
  text-align: center;
}
