.label {
  box-sizing: border-box;
  min-width: 24px;
  width: 24px;
  height: 24px;
  padding: 3px;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-text-alert);
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  box-shadow: 0 0 0 1px currentColor;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.content {
  width: 100%;
  text-align: center;
}

.tooltip {
  max-width: 300px;
  height: 32px;
  z-index: 100;
}

.tooltipContent {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.hint {
  position: relative;
  z-index: 1;
}
