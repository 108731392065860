.layout {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-bg-default);
  background-image: url('./img/auth-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media (--bp-mobile) {
    padding: 40px 0;
  }

  @media (--bp-mobile-up) {
    height: 100vh;
  }
}

.form {
  @media (--bp-mobile) {
    width: 100%;
    padding: 24px;
  }
}

.content {
  @media (--bp-mobile-up) {
    min-height: 420px;
    width: 400px;
  }
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media (--bp-mobile) {
    font-size: 28px;
  }

  @media (--bp-mobile-up) {
    font-size: 32px;
    line-height: 1.38;
  }
}

.description {
  margin-bottom: 24px;
  font-size: 16px;
}

.backButton {
  margin-top: 4px;
  margin-right: 16px;
}

.result {
  padding-top: 215px;
  margin-bottom: 24px;
  background-repeat: no-repeat;
  background-position: center top;
  text-align: center;

  @media (--bp-mobile-up) {
    width: 376px;
  }

  em {
    font-style: normal;
    color: var(--color-text-brand);
  }

  &_success {
    background-image: url('./img/success.svg');
  }

  &_failure {
    background-image: url('./img/failure.svg');
  }

  &_email {
    background-image: url('./img/email.svg');
  }
}
