.root {
  position: relative;
  overflow: hidden;
  margin-bottom: -12px;
}

.type {
  &_slider {
    width: 308px;
  }

  &_double {
    width: 615px;
  }
}

.navLeft {
  transform: rotate(180deg);
}
