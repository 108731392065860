@media (--bp-mobile) {
  .filter {
    margin-bottom: 16px;
    color: var(--color-text-primary);
  }

  .clearButton,
  .templatesButton {
    font-size: 12px;
    margin-top: 8px;
  }

  .subbuttons {
    display: flex;
    justify-content: space-between;
  }
}

@media (--bp-mobile-up) {
  .form {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
  }

  .filters {
    flex: 1;
    margin-bottom: -8px;
  }

  .filtersWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .filter {
    flex: 1;
    min-width: 200px;
    margin-right: 16px;
    margin-bottom: 8px;
    color: var(--color-text-primary);
  }

  .filterCheckbox {
    display: inline-block;
  }

  .buttons {
    position: relative;
    display: flex;
    align-items: center;
  }

  .submit {
    width: 216px;
  }

  .clearButton,
  .templatesButton {
    position: absolute;
    top: 100%;
    margin-top: 8px;
    font-size: 12px;
    line-height: 1;
    white-space: nowrap;
  }

  .clearButton {
    position: absolute;
    right: 0;
  }

  .templatesButton {
    left: 0;
  }

  .templatesMenu {
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: 8px;
    z-index: 1;
  }
}
