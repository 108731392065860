.container {
  background-color: var(--color-bg-default);
  box-shadow: var(--shadow-modal);

  @media (--bp-mobile) {
    position: relative;
    padding: 16px;
  }

  @media (--bp-mobile-up) {
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    width: 744px;
    height: 452px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 27px;
  padding-left: 27px;
}

.header {
  display: block;
  height: 68px;
  border-bottom: 1px solid var(--color-bg-stripe);
  line-height: 68px;
  text-align: center;
  font-size: 24px;
  font-weight: 300;
}

.description {
  padding-top: 16px;
  color: var(--color-text-primary);
  text-align: center;
  line-height: 1.5;
  font-size: 14px;
}

.themeList {
  display: flex;
  margin-top: 32px;
  margin-bottom: 32px;
}

.themeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 144px;
  height: 144px;
  border-radius: 50%;
  border: 3px solid transparent;
  box-sizing: border-box;
  cursor: pointer;

  & + & {
    @media (--bp-mobile) {
      margin-left: 32px;
    }

    @media (--bp-mobile-up) {
      margin-left: 64px;
    }
  }

  @mixin focus-border var(--color-bg-brand), -5px;

  &:hover {
    background-color: var(--color-bg-light);
  }

  &:active {
    background-color: var(--color-bg-accent);
  }

  &_selected {
    border-color: var(--color-bg-brand);
  }
}

.info {
  margin-top: 32px;
  color: var(--color-text-secondary);
  line-height: 1.5;
  font-size: 14px;
}
