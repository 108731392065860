.total {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.totalLabel {
  font-size: 14px;
  color: var(--color-text-secondary);
}

.totalValue {
  color: var(--color-text-primary);
  font-size: 20px;
}

@media (--bp-mobile) {
  .totalGroup {
    width: 100%;
    margin-bottom: 16px;
  }
}

@media (--bp-mobile-up) {
  .totalGroup {
    margin-right: 48px;
  }

  .totalValue {
    font-size: 24px;
  }
}
