.fieldRate {
  display: block;
  position: relative;

  &_currency {
    position: absolute;
    width: 39px;
    height: 46px;
    top: 1px;
    right: 1px;
    line-height: 46px;
    border-left: 1px solid var(--color-bg-stripe);
    background-color: var(--color-bg-accent);
    color: var(--color-text-secondary);
    font-size: 14px;
    text-align: center;
  }
}
