.root {
  display: flex;
  border-bottom: 1px solid var(--color-bg-stripe);
}

.tab {
  display: block;

  &:not(:last-child) {
    margin-right: 21px;
  }
}

.link {
  position: relative;
  display: block;
  padding-bottom: 14px;
  cursor: pointer;
  margin-bottom: -1px;
  line-height: 1;
  color: var(--color-text-secondary);

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    width: 100%;
    background-color: transparent;
    transition-duration: 0.2s;
    transition-property: background-color;
    transition-timing-function: ease-in-out;
  }

  :--focus,
  &:hover {
    color: var(--color-text-primary);
  }

  :--focus {
    outline: none;

    .link_text {
      @mixin absolute-border;
    }
  }

  &:active {
    color: var(--color-text-brand);
  }

  &.state_active {
    color: var(--color-text-primary);

    &::before {
      background-color: var(--color-bg-brand);
    }
  }
}

.link_text {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 1px;
  text-transform: uppercase;
}
