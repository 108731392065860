@import 'reset.css';
@import 'fonts.css';
@import 'design-tokens.css';
@import 'variables.css';

/* base style */
html,
body {
  font-family: var(--OpenSans);
  background-color: var(--color-bg-default);
}

body {
  color: var(--color-text-primary);
}

@media (--bp-mobile) {
  html {
    cursor: pointer /* forces iOS Safari to handle click events on document */;
  }
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

*:focus {
  outline: none;
}

:global(.focus-ring) {
  outline: 3px double var(--color-bg-brand);
}
