@media (--bp-mobile) {
  .row {
    flex-wrap: wrap;
  }

  .columnName,
  .columnClient,
  .columnDate {
    width: 100%;
    display: flex;
    align-self: flex-start;
    word-break: break-word;
  }

  .columnName,
  .columnClient {
    margin-bottom: 16px;
  }

  .columnMenu {
    order: -1;
    width: 100%;
    align-self: flex-end;
    margin-bottom: 4px;
  }

  .columnLabel {
    flex-basis: 105px;
    flex-shrink: 0;
    margin-right: 8px;
    color: var(--color-text-secondary);
  }
}

@media (--bp-mobile-up) {
  .columnCheckbox {
    width: 50px;
  }

  .action:not(:last-child) {
    margin-right: 16px;
  }

  .columnName,
  .columnClient,
  .columnDate {
    max-width: 400px;
  }

  .columnLabel {
    display: none;
  }
}
