.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  display: flex;
  margin-bottom: 3px;
  color: var(--color-text-secondary);
  font-size: 14px;
  line-height: 16px;
}

.text {
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
}

.columnName {
  overflow: initial;
}

.tooltip {
  min-width: 150px;
}

.icon {
  color: var(--color-text-caution);
}

.icon_failed {
  color: var(--color-text-alert);
}

.buttonResend {
  display: flex;
  justify-content: center;
}

.buttonResend_success {
  color: var(--color-text-success);
}

.buttonResend_failed {
  color: var(--color-text-alert);
}
