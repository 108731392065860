.chart {
  --z-bar-hover: 1;

  position: relative;
  z-index: 0;
  display: block;
  padding: 40px 0;
  user-select: none;
}

.bars {
  position: relative;
  display: flex;
  height: 255px;

  @media (--bp-mobile) {
    padding-left: 50px;
    padding-right: 16px;
  }

  @media (--bp-mobile-up) {
    padding: 0 50px;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: var(--color-bg-stripe);
    pointer-events: none;
    z-index: calc(var(--z-bar-hover) + 1);
  }
}

.chart_loading .bars {
  opacity: 0.5;
}

.barsWrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.bar {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100%;

  &_hovered {
    z-index: var(--z-bar-hover);
  }
}

.barWrapper {
  position: relative;
  display: block;
  width: 100%;
}

.barColumn {
  display: block;
  height: 100%;
  width: 70%;
  margin: 0 auto;
  background-color: var(--color-bg-brand);

  &_secondary {
    background-color: var(--color-bg-stripe);
  }

  .bars_hovered .bar:not(.bar_hovered) & {
    background-color: var(--color-bg-brand-disabled);

    &_secondary {
      background-color: var(--color-bg-light);
    }
  }
}

.value {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  text-align: center;

  &_hidden {
    visibility: hidden;
  }

  .bar_hovered & {
    visibility: visible;
  }
}

.valueSpan {
  display: inline-block;
  padding: 7px;
  background-color: var(--color-bg-default);
  border: 1px solid var(--color-bg-stripe);
  border-radius: 15px;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-text-secondary);
  line-height: 1;
  white-space: nowrap;

  .bars_hovered .bar:not(.bar_hovered) & {
    color: var(--color-text-disabled);
  }
}

.label {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding-top: 8px;
  text-align: center;
  font-size: 12px;
  color: var(--color-text-secondary);
  white-space: nowrap;

  &_hidden {
    visibility: hidden;
  }

  .bars_hovered & {
    color: var(--color-text-disabled);
  }

  .bar_hovered & {
    visibility: visible;
    color: var(--color-text-secondary);
  }

  @media (--bp-mobile) {
    visibility: hidden;

    .bar_hovered & {
      visibility: visible;
    }
  }
}

.labelWeekDay {
  margin-top: 16px;
  display: block;
  white-space: nowrap;
  padding: 0 5px;
}

.labelSpan {
  display: block;
  white-space: nowrap;
  padding: 0 5px;
  line-height: 1;
}

.grid {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.gridSection {
  display: block;
  padding-top: 4px;
  border-top: 1px solid var(--color-bg-stripe);
  font-size: 12px;
  color: var(--color-text-secondary);
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
