.button {
  @media (--bp-mobile) {
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 1;
  }

  @media (--bp-mobile-up) {
    display: none;
  }
}
