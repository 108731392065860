.header {
  display: block;
  background-color: var(--color-bg-default);
}

.backlink {
  display: inline-block;
  margin-right: 4px;
  margin-left: -2px;
}

.content {
  display: block;

  @media (--bp-mobile-up) {
    padding: 28px 32px;
  }

  @media (--bp-mobile) {
    padding: 16px;
  }
}

.title {
  display: block;
  color: var(--color-text-primary);

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.titleCaption {
  display: inline-block;
}

.titleBackDescription {
  display: block;
  font-size: 14px;
  line-height: 20px;
}

.description {
  color: var(--color-text-primary);
  font-size: 14px;
  line-height: 20px;
}

.close {
  position: absolute;
  z-index: 1;

  @media (--bp-mobile) {
    top: 24px;
    right: 16px;
  }

  @media (--bp-mobile-up) {
    top: 32px;
    right: 20px;
  }
}
