@media (--bp-mobile) {
  .wrapper {
    flex-direction: column;
  }

  .title {
    display: none;
  }

  .field {
    width: 100%;
  }
}

@media (--bp-mobile-up) {
  .wrapper {
    flex-direction: row;
  }

  .field {
    width: 272px;
    margin-bottom: 32px;
  }

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }
}

.section {
  display: block;
  margin-bottom: 32px;
  max-width: 568px;
}

.wrapper {
  padding-bottom: 32px;
  margin-bottom: 32px;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--color-bg-stripe);
}

.wrapperSettings {
  padding-bottom: 0;
}
