.container {
  @media (--bp-mobile-up) {
    margin-top: 32px;
  }
}

.title {
  @media (--bp-mobile) {
    display: none;
  }
}
