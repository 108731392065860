.projectsList {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-bottom: -16px;
}

.project {
  margin-right: 16px;
  margin-bottom: 16px;
  padding: 7px 15px;
  border: 1px solid var(--color-bg-stripe);
  background-color: var(--color-bg-default);
  color: var(--color-text-secondary);
  border-radius: 4px;
  font-size: 14px;
}

@media (--bp-mobile) {
  .row {
    flex-wrap: wrap;
  }

  .columnName,
  .columnProjects {
    align-self: flex-start;
  }

  .columnName {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .columnProjects {
    width: 100%;
  }

  .columnMenu {
    width: 100%;
    order: -1;
    margin-bottom: 4px;
  }
}

@media (--bp-mobile-up) {
  .action:not(:last-child) {
    margin-right: 16px;
  }

  .columnName {
    max-width: 400px;
  }

  .client {
    display: block;
    padding: 8px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
