.container {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 1;

  @media (--bp-mobile-up) {
    display: none;
  }
}

.menu {
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;
}
