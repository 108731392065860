.root {
  display: block;
  color: inherit;
}

.label {
  display: flex;
  align-items: center;
  height: 16px;
  font-size: 14px;
  line-height: 1.25;
  color: inherit;
  user-select: none;
  cursor: pointer;

  &_disabled {
    color: var(--color-text-disabled);
    pointer-events: none;
  }
}

.checkmark {
  position: relative;
  width: 16px;
  height: 16px;
  background-color: var(--color-bg-component);
  border: 1px solid var(--color-bg-stripe);
  flex-shrink: 0;

  &_checked {
    background-color: var(--color-bg-brand);
    border: none;

    &::after {
      position: absolute;
      top: 3px;
      left: 6px;
      width: 4px;
      height: 8px;
      border-right: 1px solid var(--color-bg-component);
      border-bottom: 1px solid var(--color-bg-component);
      content: '';
      transform: rotate(30deg);
    }
  }

  .input:disabled + & {
    background-color: var(--color-bg-component-disabled);

    &_checked {
      background-color: var(--color-bg-brand-disabled);
    }
  }

  .input:global(.focus-ring) + & {
    @mixin absolute-border var(--color-bg-stripe-hover);

    &_checked {
      @mixin absolute-border var(--color-bg-brand-hover), -2px;
    }
  }

  .label:hover & {
    &_checked {
      background-color: var(--color-bg-brand-hover);
    }

    &:not(&_checked) {
      background-color: var(--color-bg-light);
      border-color: var(--color-bg-stripe-hover);
    }
  }

  .input:active + &,
  .label:active & {
    &_checked {
      background-color: var(--color-bg-brand-active);
    }
  }
}

.content {
  margin-left: 8px;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: inherit;
}

.input {
  @mixin visually-hidden;
}
