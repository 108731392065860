.rangePicker {
  position: relative;
}

.button {
  color: inherit;
}

.buttonWrapper {
  display: flex;
  align-items: center;
}

.expand {
  display: flex;
}

.dropdown {
  position: absolute;
  z-index: 2;
}

@media (--bp-mobile) {
  .button {
    width: 100%;
    font-weight: 600;
    font-size: 14px;

    @mixin color-primary-states;
  }

  .buttonWrapper {
    justify-content: space-between;
    padding: 14px 16px;
    border-bottom: 1px solid var(--color-bg-stripe);
  }

  .expand {
    width: 10px;
    height: 10px;
  }

  .expand.expanded {
    transform: rotate(180deg);
    transform-origin: 50% 50%;
  }

  .dropdown {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .periods {
    display: flex;
    flex-wrap: wrap;
    padding: 16px;
    margin-right: -16px;
    margin-bottom: -16px;
  }

  .period {
    margin-right: 16px;
    margin-bottom: 16px;
    font-size: 14px;
  }
}

@media (--bp-mobile-up) {
  .button {
    padding-right: 8px;

    @mixin color-secondary-states;
    @mixin focus-border;
  }

  .caption {
    margin-right: 10px;
    font-size: 28px;
    font-weight: 300;
  }

  .expand {
    width: 12px;
    height: 12px;
  }

  .dropdown {
    left: 0;
  }

  .periods {
    padding: 20px;
  }

  .period {
    display: inline-block;
    width: 25%;
    padding: 7px 0;
  }
}
