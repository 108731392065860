.logo {
  display: block;
  width: 152px;
  height: 43px;
  background-repeat: no-repeat;
  background-position: left center;
  cursor: pointer;
  background-image: url('./img/logo-dark.svg');

  :--dark-theme {
    background-image: url('./img/logo.svg');
  }
}
