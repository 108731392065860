.root {
  background-color: var(--color-bg-component);
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-bg-stripe);
}

.list {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: var(--color-bg-component);
  list-style: none;
}

.item {
  display: block;
  width: 100%;
  color: var(--color-text-primary);
  background-color: var(--color-bg-component);
  white-space: nowrap;
}

.content {
  padding-top: 15px;
  padding-bottom: 15px;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.25;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  color: inherit;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  &_dense {
    padding-left: 14px;
    padding-right: 14px;
  }

  &_regular {
    padding-left: 24px;
    padding-right: 24px;
  }

  &_warning {
    color: var(--color-text-alert);
  }

  &:hover {
    background-color: var(--color-bg-light);
  }

  &:active {
    color: var(--color-text-brand);
  }

  :--focus,
  &_focused {
    color: var(--color-text-primary);
    background-color: var(--color-bg-light);
    outline: none;
    border-top: 1px solid var(--color-bg-stripe-hover);
    border-bottom: 1px solid var(--color-bg-stripe-hover);
  }

  &_active {
    color: var(--color-text-active);
    background-color: var(--color-bg-brand);
    pointer-events: none;
  }

  &:disabled {
    pointer-events: none;
    color: var(--color-text-disabled);
  }
}

.divider {
  margin-top: 8px;
  margin-bottom: 8px;
  display: block;
  height: 1px;
  background-color: var(--color-bg-stripe);
}
