.formInner {
  padding-top: 13px;
  padding-bottom: 3px;
}

.toggle {
  padding-top: 17px;
  padding-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
}

.toggleName {
  display: block;
  flex-grow: 1;
}
