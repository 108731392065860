.label {
  display: inline-flex;
  align-items: center;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  cursor: inherit;
  white-space: inherit;
  overflow: inherit;
  text-overflow: inherit;
}

.labelTag {
  flex-shrink: 0;
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 100%;
  background-color: var(--color-bg-alert);
  color: var(--color-text-active);
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  cursor: default;
}

.labelHint {
  max-width: 300px;
}
