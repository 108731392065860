.wrapper {
  top: 0;
  right: 0;

  &:not(:last-child) {
    @media (--bp-mobile) {
      margin-bottom: 8px;
    }

    @media (--bp-mobile-up) {
      margin-bottom: 16px;
    }
  }

  @media (--bp-mobile-up) {
    width: 250px !important;
  }
}
