.tableWrapper {
  width: 100%;
}

.row {
  position: relative;
}

@media (--bp-mobile) {
  .options {
    margin-bottom: 16px;
  }

  .title,
  .titleExpand {
    display: flex;
    text-align: left;
    color: inherit;
  }

  .expandIcon {
    display: none;
  }

  .row {
    flex-direction: column;
    margin-bottom: 16px;
  }

  .row:not(.rowParent) {
    margin-left: 16px;
  }

  .column {
    display: flex;
    margin-bottom: 16px;
  }

  .column_expand {
    margin-bottom: 0;
    order: 10;
  }

  .columnLabel {
    flex-shrink: 0;
    width: 82px;
    margin-right: 8px;
    color: var(--color-text-secondary);
  }

  .expandButtonMobile {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .expandButtonMobileIcon {
    margin-left: 8px;

    .row_expanded & {
      transform: rotate(180deg);
      transform-origin: 50% 50%;
    }
  }

  .rateLabel {
    position: absolute;
    top: 6px;
    right: 4px;
    width: 36px;
    height: 36px;
    font-size: 16px;
  }

  .columnTitleNarrow {
    padding-right: 28px;
  }
}

@media (--bp-mobile-up) {
  .options {
    margin-bottom: 32px;
  }

  .table {
    transition: 0.2s opacity;
  }

  .table_loading {
    opacity: 0.5;
  }

  .columnTitle {
    width: 28%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }

  .columnDate,
  .columnTotal {
    width: 9%;
    min-width: 50px;
  }

  .columnDate_disabled,
  .columnTotal_disabled {
    color: var(--color-text-disabled);
  }

  .column_expand {
    display: none;
  }

  .columnLabel {
    display: none;
  }

  .rowTotal .column {
    font-weight: bold;
  }

  .title,
  .titleExpand {
    font-size: inherit;
    color: inherit;
    line-height: inherit;
    font-weight: inherit;
    white-space: inherit;
    overflow: inherit;
    text-overflow: inherit;
  }

  .title {
    display: flex;
    padding-left: 42px;
  }

  .rowTotal .title {
    display: none;
  }

  .titleExpand {
    display: flex;
    width: 100%;
    padding: 16px 0;
    margin: -16px 0;
    align-items: center;
    background: none;
    border: none;
    font-weight: 600;
    cursor: pointer;

    @mixin color-primary-states;

    :--focus {
      outline: none;
      text-decoration: underline;
    }
  }

  .expandIcon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    margin-top: -1px;
    margin-bottom: -1px;
    margin-right: 8px;
  }

  .expandIcon_expanded {
    transform: rotate(180deg);
    transform-origin: 50% 50%;
  }

  .rateLabel {
    position: absolute;
    top: 14px;
    left: 26px;
  }

  .titleText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
