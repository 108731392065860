.position_left {
  padding-top: 8px;
  padding-left: 16px;
  transform: translateX(5px);
}

.position_right {
  padding-top: 8px;
  transform: translateX(-5px);
}

.position_top {
  padding-left: 8px;
  padding-top: 16px;
  transform: translateY(5px);
}

.position_bottom {
  padding-left: 8px;
  transform: translateY(-5px);
}

.content {
  --tooltipBackgroundColor: var(--color-bg-popup);

  position: relative;
  display: flex;
  user-select: none;
  padding: 8px 16px;
  background-color: var(--tooltipBackgroundColor);
  color: var(--color-text-active);
  font-size: 12px;
  line-height: 16px;
  max-width: 320px;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
  }
}

.content_position_left {
  &::before {
    right: auto;
    right: -6px;
    border-width: 6px 0 6px 6px;
    border-left-color: var(--tooltipBackgroundColor);
  }
}

.content_position_left-top {
  transform: translateX(6px) translateY(-14px);

  &::before {
    top: 8px;
  }
}

.content_position_left-center {
  transform: translateX(calc(-100% + 6px)) translateY(-50%);

  &::before {
    top: calc(50% - 6px);
  }
}

.content_position_left-bottom {
  transform: translateX(6px) translateY(calc(-100% + 14px));

  &::before {
    bottom: 8px;
  }
}

.content_position_top {
  &::before {
    top: -6px;
    border-width: 0 6px 6px;
    border-bottom-color: var(--tooltipBackgroundColor);
  }
}

.content_position_top-left {
  transform: translateX(-14px) translateY(6px);

  &::before {
    left: 8px;
  }
}

.content_position_top-center {
  transform: translateX(-50%) translateY(6px);

  &::before {
    left: 0;
    right: 0;
    margin: auto;
  }
}

.content_position_top-right {
  transform: translateX(calc(-100% + 14px)) translateY(6px);

  &::before {
    right: 8px;
  }
}

.content_position_right {
  &::before {
    right: -6px;
    border-width: 6px 0 6px 6px;
    border-left-color: var(--tooltipBackgroundColor);
  }
}

.content_position_right-top {
  transform: translateX(calc(-100% - 6px)) translateY(-14px);

  &::before {
    top: 8px;
  }
}

.content_position_right-bottom {
  transform: translateX(calc(-100% - 6px)) translateY(calc(-100% + 14px));

  &::before {
    bottom: 8px;
  }
}

.content_position_right-center {
  transform: translateX(calc(-100% - 6px)) translateY(-50%);

  &::before {
    top: calc(50% - 6px);
  }
}

.content_position_bottom {
  &::before {
    bottom: -6px;
    border-width: 6px 6px 0;
    border-top-color: var(--tooltipBackgroundColor);
  }
}

.content_position_bottom-left {
  transform: translateX(-14px) translateY(calc(-100% - 6px));

  &::before {
    left: 8px;
  }
}

.content_position_bottom-center {
  transform: translateX(-50%) translateY(calc(-100% - 6px));

  &::before {
    left: calc(50% - 6px);
  }
}

.content_position_bottom-right {
  transform: translateX(calc(-100% + 14px)) translateY(calc(-100% - 6px));

  &::before {
    right: 8px;
  }
}
