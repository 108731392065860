@media (--bp-mobile) {
  .row {
    flex-direction: column;
  }

  .columnName,
  .columnRole,
  .columnPosition,
  .columndate,
  .columnRate {
    display: flex;
    margin-bottom: 16px;
    align-self: flex-start;
  }

  .columnMenu {
    order: -1;
    align-self: flex-end;
    margin-bottom: -24px;
  }

  .rowValue {
    display: flex;
    flex-direction: column;
  }

  .columnLabel {
    width: 82px;
    margin-right: 8px;
    color: var(--color-text-secondary);
  }
}

@media (--bp-mobile-up) {
  .columnName,
  .columnRole,
  .columnPosition {
    min-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 20px;
  }

  .columnName {
    min-width: 200px;
    margin-right: 40px;
  }

  .columnName:nth-last-child(2):first-child,
  .columnName:nth-last-child(2):first-child ~ .columnName {
    width: 80%;
  }

  .columnRole {
    width: 72px;
    margin-right: 62px;
    line-height: 1.5;
  }

  .columnPosition {
    min-width: 72px;
    margin-right: 64px;
  }

  .columnRate {
    width: 110px;
    margin-right: 32px;
  }

  .columndate {
    width: 80px;
    margin-right: 32px;
  }

  .columnMenu {
    min-width: 24px;
  }

  .row {
    .columnRole {
      color: var(--color-text-secondary);
    }
  }

  .columnLabel {
    display: none;
  }

  .userProfileLink {
    color: inherit;
  }
}

.emailText {
  color: var(--color-text-secondary);
  line-height: 1.5;
}
