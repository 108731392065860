.container {
  position: relative;
}

.title {
  margin-bottom: 32px;

  @media (--bp-mobile) {
    display: none;
  }
}

.form {
  margin-bottom: 34px;

  @media (--bp-mobile-up) {
    &_fixed {
      height: 52px;
    }
  }
}

@media (--bp-mobile-up) {
  .form_fixed .formWrapper {
    position: fixed;
    top: 0;
    left: var(--sidebar-width-desktop);
    right: 0;
    z-index: 1;
    padding: 34px 48px;
    background-color: var(--color-bg-default);
    box-shadow: var(--shadow-light);
    animation-name: slideDown;
    animation-duration: 300ms;
    animation-timing-function: ease-out;
  }
}

.section {
  margin-bottom: 34px;
}

.notification {
  position: fixed;
  left: 50%;
  top: 0;
  transform: translate(-50%);
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}
