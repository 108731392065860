.question {
  display: inline-block;
  position: relative;
  font-size: 9px;
  line-height: 14px;
  text-align: center;
  border: 1px solid var(--color-bg-caution);
  color: var(--color-text-caution);
  border-radius: 50%;
  box-sizing: border-box;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  margin-left: 10px;
  z-index: 1;

  &::after {
    content: '?';
  }
}

.locked,
.approved {
  display: inline-block;
  vertical-align: bottom;
  margin-right: 6px;
}

.columnTitle {
  width: 100%;

  display: inline-flex;
}

.row {
  position: relative;
}

.rateLabel {
  z-index: 1;
}

@media (--bp-mobile) {
  .questionDoubleRate {
    position: absolute;
    top: 48px;
    right: 14px;
  }

  .row:not(.rowParent) {
    margin-left: 16px;
  }

  .row {
    flex-direction: column;
  }

  .column {
    display: flex;
    margin-bottom: 16px;
  }

  .column_expand {
    margin-bottom: 0;
    order: 10;
  }

  .columnLabel {
    flex-shrink: 0;
    width: 82px;
    margin-right: 8px;
    color: var(--color-text-primary);
  }

  .link {
    color: inherit;
    max-width: 180px;
  }

  .linkParent {
    font-weight: 600;
  }

  .expandButton {
    display: none;
  }

  .expandButtonMobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .expandButtonMobileIcon {
    margin-left: 8px;

    .row_expanded & {
      transform: rotate(180deg);
      transform-origin: 50% 50%;
    }
  }

  .rateLabel {
    position: absolute;
    top: 6px;
    right: 4px;
    width: 36px;
    height: 36px;
    font-size: 16px;
  }
}

@media (--bp-mobile-up) {
  .column {
    margin-right: 24px;

    @media (min-width: 1440px) {
      margin-right: 64px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .columnContent {
    width: 50%;
    max-width: 477px;
  }

  .title {
    display: inline;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
  }

  .row:not(.rowParent) .title {
    padding-left: 42px;
  }

  .columnProject {
    min-width: 144px;
    width: 20%;
  }

  .columnUser {
    min-width: 144px;
    width: 20%;
  }

  .columnDuration {
    width: 122px;
  }

  .columnDurationDec {
    width: 90px;
  }

  .columnInternalTotal,
  .columnExternalTotal {
    width: 124px;
  }

  .column_expand {
    display: none;
  }

  .columnLabel {
    display: none;
  }

  .rateLabel {
    position: absolute;
    top: 50%;
    left: 26px;
    transform: translateY(-50%);
  }

  .row {
    .columnUser,
    .columnProject,
    .columnDurationDec {
      color: var(--color-text-secondary);
    }
  }

  .expandButton {
    margin-top: -1px;
    margin-bottom: -1px;
    margin-right: 8px;
    background: none;
    border: 1px solid transparent;
    cursor: pointer;

    @mixin color-primary-states;
    @mixin focus-border currentColor, -1px;
  }

  .expandButtonIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 18px;
    height: 18px;

    .row_expanded & {
      transform: rotate(180deg);
      transform-origin: 50% 50%;
    }
  }

  .link {
    @mixin color-primary-states;

    :--focus {
      outline: none;
      text-decoration: underline;
    }
  }

  .linkParent {
    font-weight: 600;
  }
}
