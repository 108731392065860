.label {
  display: flex;
  align-items: center;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  cursor: inherit;
  white-space: inherit;
  overflow: inherit;
  text-overflow: inherit;
}

.labelMarker {
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 4px;
  cursor: inherit;
}

.labelContent {
  white-space: inherit;
  overflow: inherit;
  text-overflow: inherit;
}
