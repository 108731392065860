.container {
  margin: 145px 20px 0;
}

.title {
  font-weight: 700;
  text-align: center;
  font-size: 48px;
  line-height: 58px;
  max-width: 500px;
  margin: 0 auto 26px;
  text-transform: uppercase;
  white-space: pre-line;
}

.description {
  font-weight: 400;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 69px;
}

.timerWrapper {
  max-width: 904px;
  margin: 0 auto 71px auto;

  @media (--bp-mobile-up) {
    display: flex;
    align-items: center;
  }
}

.textFieldWrapper {
  margin-bottom: 16px;

  @media (--bp-mobile-up) {
    flex: auto;
    margin-right: 14px;
    margin-bottom: 0;
  }
}

.textFieldSmallWrapper {
  margin-bottom: 16px;

  @media (--bp-mobile-up) {
    width: 208px;
    flex: none;
    margin-bottom: 0;
  }
}

.timerDuration {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-align: center;

  @media (--bp-mobile-up) {
    margin-left: 19px;
  }
}

.buttonWrapper {
  text-align: center;
}
