.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  overflow-y: auto;

  @media (--bp-mobile-up) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modalOverlay {
  position: fixed;
  width: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  background-color: var(--color-bg-backdrop);
}

.modalContent {
  @media (--bp-mobile) {
    width: 100%;
    padding: 16px;
  }

  @media (--bp-mobile-up) {
    padding: 30px;
  }
}
