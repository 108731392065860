@media (--bp-mobile) {
  .title {
    display: none;
  }

  .nav {
    display: flex;
    flex-direction: column;
  }

  .archive {
    order: 2;
    margin-bottom: 16px;
  }
}

@media (--bp-mobile-up) {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  .nav {
    position: relative;
    z-index: 0;
  }

  .archive {
    position: absolute;
    right: 0;
    z-index: 1;
  }

  .actions {
    display: flex;
  }
}
