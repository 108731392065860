.exportToFile {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.exportToFile button {
  max-width: 190px;
}

.loading {
  margin-right: 10px;
}
