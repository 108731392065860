.control {
  width: 100%;
  height: 48px;
  justify-content: center;
  margin: 0;
}

.row {
  display: grid;
  grid-template-columns: 1fr 120px;
  grid-column-gap: 8px;

  & + & {
    margin-top: 16px;
  }

  @media (--bp-mobile) {
    & :global(.DatePicker_dropdown) {
      left: 0;
      right: auto;
    }
  }
}

.verifiedDate {
  grid-column-start: 1;
  grid-column-end: 2;
}

.actions {
  display: flex;
  justify-content: flex-end;
}
