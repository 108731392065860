.lockLabel,
.approveLabel {
  display: inline-block;
  vertical-align: top;
  margin-right: 6px;
}

.row {
  position: relative;
}

.options {
  display: flex;
  justify-content: flex-end;
}

.rateLabel {
  z-index: 1;
}

@media (--bp-mobile) {
  .options {
    margin-bottom: 24px;
  }

  .row {
    flex-direction: column;
  }

  .header {
    display: block;
  }

  .header .checkbox {
    padding: 16px;
  }

  .header .column:not(.columnCheckbox) {
    display: none;
  }

  .header .columnCheckbox {
    margin-left: 16px;
  }

  .column {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .columnLabel {
    flex-shrink: 0;
    width: 82px;
    margin-right: 8px;
    color: var(--color-text-secondary);
  }

  .columnUserProject {
    display: none;
  }

  .columnProject {
    position: relative;
  }

  .rateLabel {
    position: absolute;
    top: -14px;
    right: -10px;
  }

  .checkbox {
    margin-left: -8px;
    font-size: 14px;
  }

  .date,
  .duration {
    margin-right: 8px;
  }

  .issue {
    margin-bottom: 8px;
  }

  .durationDec,
  .description {
    color: var(--color-text-secondary);
  }

  .rateWrapper {
    position: absolute;
    top: 6px;
    right: 4px;
    width: 36px;
    height: 36px;
    font-size: 16px;
  }
}

@media (--bp-mobile-up) {
  .options {
    margin-bottom: 32px;
  }

  .export {
    width: 260px;
    margin-top: 30px;
  }

  .table {
    transition: 0.2s opacity;
    min-width: 850px;
  }

  .table_loading {
    opacity: 0.5;
  }

  .row {
    padding: 24px 16px;
  }

  .column {
    white-space: normal;

    &:last-child {
      margin-right: 0;
    }
  }

  .columnCheckbox {
    width: 43px;
    height: 16px;
  }

  .columnDate {
    width: 152px;
  }

  .columnDescription {
    width: 35%;
  }

  .columnUserProject {
    padding-right: 44px;
    position: relative;
  }

  .columnDuration {
    width: 102px;
  }

  .columnUser,
  .columnProject,
  .columnLabel {
    display: none;
  }

  .header .checkbox {
    margin-top: -6px;
  }

  .row .checkbox {
    top: 6px;
  }

  .checkboxLabel {
    display: none;
  }

  .date,
  .time,
  .issue,
  .description,
  .duration,
  .durationDec,
  .user,
  .project {
    display: block;
    font-size: inherit;
    color: inherit;
    line-height: inherit;
  }

  .date,
  .duration,
  .user {
    line-height: 16px;
    margin-bottom: 8px;
  }

  .issue {
    margin-top: -3px;
    line-height: 1.64;
    word-break: break-word;
  }

  .description {
    margin-top: 8px;
    font-size: 12px;
    line-height: 1.17;
  }

  .time,
  .durationDec,
  .description,
  .project {
    line-height: 1;
    color: var(--color-text-secondary);
  }

  .loadMore {
    display: block;
    width: 100%;
    padding-top: 14px;
    padding-bottom: 14px;
    text-align: center;
    border-bottom: 1px solid var(--color-bg-stripe);
  }

  .rateLabel {
    position: absolute;
    top: 20px;
    right: 14px;
  }
}

@media (min-width: 1228px) {
  .export {
    margin-top: 0;
  }
}
