.line {
  &_hasSubscription {
    background-color: var(--color-bg-stripe);
  }

  &_willExpire {
    background-color: var(--color-bg-caution);
  }
}

@media (--bp-mobile) {
  .filters {
    margin-bottom: 16px;
  }

  .header {
    display: block;
  }

  .header .column:not(.columnСheckbox) {
    display: none;
  }

  .header .columnСheckbox {
    padding-left: 17px;
  }

  .header .checkbox {
    margin-bottom: 16px;
  }

  .checkbox {
    margin-left: -8px;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .row {
    flex-direction: column;
  }

  .row .columnСheckbox {
    position: absolute;
  }

  .columnClient,
  .columnPosition,
  .columnRate,
  .columnDate {
    display: flex;
    margin-bottom: 16px;
    align-self: flex-start;
  }

  .columnMenu {
    order: -1;
    align-self: flex-end;
    margin-bottom: 16px;
  }

  .columnLabel {
    flex-shrink: 0;
    width: 82px;
    margin-right: 8px;
    color: var(--color-text-secondary);
  }

  .line {
    width: 4px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -16px;
  }
}

@media (--bp-mobile-up) {
  .action:not(:last-child) {
    margin-right: 16px;
  }

  .filters {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  .filter {
    display: block;
    flex-grow: 1;
    min-width: 200px;
    max-width: 250px;
  }

  .line {
    width: 4px;
    height: calc(100% - 4px);
    position: absolute;
    top: 2px;
    left: 0;
    display: block;
  }

  .columnCheckbox {
    position: relative;
    width: 50px;
  }

  .checkboxLabel {
    display: none;
  }

  .columnClient,
  .columnPosition {
    width: 20%;
  }

  .columnClient {
    max-width: 340px;
  }

  .columnPosition {
    max-width: 280px;
  }

  .columnRate,
  .columnDate {
    width: 10%;
    min-width: 100px;
  }

  .columnMenu {
    min-width: 24px;
    padding-right: 16px;
  }

  .columnLabel {
    display: none;
  }
}
