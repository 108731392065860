.form {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -24px;

  @media (--bp-mobile-up) {
    margin-right: -24px;
  }
}

.formControl {
  margin-bottom: 24px;
  width: 100%;

  @media (--bp-mobile-up) {
    display: inline-block;
    width: 272px;
    margin-right: 24px;
  }
}
