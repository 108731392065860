.empty {
  @media (--bp-mobile) {
    display: none;
  }

  @media (--bp-mobile-up) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    background-image: url('./img/theme-light.svg');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;

    :--dark-theme {
      background-image: url('./img/theme-dark.svg');
    }
  }
}

.emptyTitle {
  margin-bottom: 4px;
}
