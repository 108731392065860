@media (--bp-mobile) {
  .root {
    width: 100%;
  }

  .header {
    display: none;
  }

  .row {
    display: flex;
    padding: 16px;
    border: 1px solid var(--color-bg-stripe);
    border-radius: 4px;
    box-shadow: var(--shadow-light);
    color: var(--color-text-primary);

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .rowColumn {
    display: block;
    font-size: 14px;
  }
}

@media (--bp-mobile-up) {
  .root {
    width: 100%;
  }

  .headerCell:last-child,
  .tableCell:last-child {
    flex-grow: 1;
  }

  .header {
    display: table-row;
    color: var(--color-text-secondary);
    background-color: var(--color-bg-accent);
  }

  .row {
    display: table-row;
    padding: 16px;
    color: var(--color-text-primary);
    border-top-width: 0;
    border-bottom-width: 1px;
    border-right-width: 0;
    border-left-width: 0;
    border-style: solid;
    border-color: var(--color-bg-stripe);
  }

  .row:hover,
  .row_active {
    position: relative;
    background-color: var(--color-bg-light);
  }

  .headerColumn,
  .rowColumn {
    display: table-cell;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1.25;
    text-align: left;
    color: inherit;
  }

  .rowColumn:last-child,
  .headerColumn:last-child {
    padding-right: 16px;
  }

  .rowColumn_align_right,
  .headerColumn_align_right {
    text-align: end;
  }

  .rowColumn_align_center,
  .headerColumn_align_center {
    text-align: center;
  }

  .headerColumn_sorroot {
    font-weight: 600;
    padding-left: 16px;
  }

  .headerButton {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-align: inherit;
    max-width: 100%;

    /* Focus border is cut off in columns with `overflow: hidden`, so we replace it with underlining  */
    @mixin focus-border transparent;

    :--focus {
      text-decoration: underline;
    }
  }

  .sortIcon {
    display: inline-block;
  }

  .headerColumn_sort_asc {
    .sortIcon {
      transform: rotate(180deg);
    }
  }
}
