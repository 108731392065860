.datePicker {
  display: block;
  position: relative;

  :global {
    .DayPicker_weekHeader {
      top: 46px;
    }

    .DayPicker_weekHeader ul {
      padding-left: 7px;
    }

    .DayPicker_weekHeader li {
      margin-right: 3px;
    }
  }
}

.button {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 12px;
  right: 8px;
  cursor: pointer;

  @mixin color-secondary-states;

  &_opened {
    @mixin color-brand-states;
  }
}

.dropdown {
  position: absolute;
  z-index: 2;
  right: 0;
}

.position {
  &_top {
    bottom: 100%;
  }

  &_bottom {
    top: 100%;
  }
}
