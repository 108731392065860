.workspacesWraper {
  position: relative;
}

.current {
  color: var(--color-text-secondary);
}

.columnName {
  align-items: baseline;
}

.link {
  width: 100%;
}

@media (--bp-mobile) {
  .row {
    align-items: center;
  }

  .columnName {
    flex-grow: 1;
  }
}

@media (--bp-mobile-up) {
  .action {
    margin-left: auto;
  }

  .filters {
    display: flex;
    margin-bottom: 32px;
  }

  .current {
    margin-left: 16px;
    font-size: 12px;
    line-height: 1.33;
  }
}
