@media (--bp-mobile) {
  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    z-index: 1;
  }

  .action:not(:last-child) {
    margin-right: 16px;
  }
}

@media (--bp-mobile-up) {
  .form {
    display: flex;
    align-items: center;
  }

  .input {
    width: 114px;
  }

  .actions {
    display: flex;
    margin-left: 16px;
    z-index: 1;
  }

  .action:not(:last-child) {
    margin-right: 16px;
  }
}
