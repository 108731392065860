:global {
  .DayPicker_weekHeaders {
    position: relative;
  }

  .DayPicker__vertical div .DayPicker_weekHeaders {
    height: 40px;
  }

  .Calendar_type_slider,
  .Calendar_type_double {
    .DateRangePicker {
      padding-top: 0;
      width: 308px;
      display: table-cell;
      vertical-align: top;
    }

    .DayPicker_weekHeader {
      top: 48px;
    }

    .DayPicker_weekHeader ul {
      padding-left: 8px;
    }
  }

  .Calendar_type_slider {
    .DayPicker__horizontal {
      width: 308px !important;
    }

    .transition-container__horizontal {
      width: 304px !important;
    }
  }

  .CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
  }

  .Calendar_type_double {
    .CalendarMonthGrid_month__horizontal .CalendarMonth {
      margin-right: 14px;
      position: relative;

      &::after,
      &::before {
        position: absolute;
        top: 120px;
        bottom: 32px;
        width: 1px;
        height: auto;
        content: '';
        background-color: var(--color-bg-stripe);
      }

      &::after {
        left: 301px;
      }

      &::before {
        right: 300px;
      }
    }

    .DayPickerNavigation__horizontal {
      .DayPickerNavigation__next {
        right: 33px;
      }
    }

    .DayPicker_weekHeaders {
      position: relative;
    }

    .DayPicker_weekHeader {
      z-index: auto;
    }

    .DayPicker_weekHeader:first-child::after {
      position: absolute;
      top: 0;
      right: 324px;
      z-index: 10;
      width: 1px;
      height: 100%;
      content: '';
      background-color: var(--color-bg-default);
    }

    .DayPicker_weekHeader:last-child {
      left: 309px !important;
    }
  }

  .DateRangePicker {
    padding-top: 51px;
    position: relative;
    width: 100%;
  }

  .DateRangePickerInput {
    display: none;
  }

  .DayPicker_transitionContainer {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 3px;
    padding-bottom: 20px;
    box-sizing: content-box;
  }

  .DayPicker_transitionContainer__vertical {
    height: 412px !important;
    overflow-y: auto;
  }

  .DayPicker_transitionContainer__horizontal {
    transition: height 0.2s ease-in-out;
  }

  .DayPicker_weekHeader {
    margin-left: 0 !important;
    padding: 0 !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    text-align: left;

    height: 40px;
    background-color: var(--color-bg-accent);
    color: var(--color-text-primary);
  }

  .DayPicker_weekHeader ul {
    margin: 0;
    padding: 0;
    padding-top: 7px;
    padding-left: 23px;
    list-style: none;
  }

  .DayPicker_weekHeader li {
    margin-right: 2px;
    display: inline-block;
    text-align: center;
  }

  .DayPickerNavigation {
    display: none;
  }

  .DayPickerNavigation__horizontal {
    display: block;

    .DayPickerNavigation_button {
      padding: 0;
      position: absolute;
      top: 14px;
      z-index: 10;
      width: 20px;
      height: 20px;
      border: none;
      background: none;
      color: var(--color-text-primary);
      cursor: pointer;
    }

    .DayPickerNavigation_button:first-child {
      left: 16px;
    }

    .DayPickerNavigation_button:last-child {
      right: 14px;
    }
  }

  .DayPickerKeyboardShortcuts_showSpan {
    display: none;
  }

  .CalendarMonthGrid {
    padding-left: 22px;
  }

  .CalendarMonthGrid__animating {
    transition: transform 0.2s ease-in-out;
    z-index: 2;
  }

  .CalendarMonthGrid__horizontal {
    position: absolute;
    padding-left: 6px;
    z-index: 1;
    text-align: left;
  }

  .CalendarMonth {
    position: relative;
    padding-top: 36px;
    margin-bottom: 12px;

    &::after {
      position: absolute;
      bottom: -10px;
      left: -22px;
      width: 111%;
      height: 1px;
      content: '';
      background-color: var(--color-bg-stripe);
    }
  }

  .CalendarMonth table {
    border-collapse: collapse;
  }

  .CalendarMonth.CalendarMonth__vertical:last-of-type::after {
    display: none;
  }

  .CalendarMonthGrid_month__hidden {
    visibility: hidden;
  }

  .CalendarMonthGrid_month__horizontal {
    display: inline-block;

    .CalendarMonth {
      margin-right: 4px;
      margin-bottom: 0;
      padding: 88px 0 0 !important;
      min-height: 100%;
      display: inline-block;
      vertical-align: top;

      &::after {
        content: none;
      }

      .CalendarMonth_caption {
        top: 0;
        left: 50%;
        transform: translateX(-50%);

        padding-top: 12px;
        padding-left: 0;
        height: 76px;
        text-align: center;
      }
    }
  }

  .CalendarMonth_caption {
    position: absolute;
    top: 10px;
    left: 0;
    height: 26px;
    text-align: left;

    strong {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      color: var(--color-text-primary);
    }
  }

  .CalendarDay {
    position: relative;
    padding: 0;
    width: 42px !important;
    height: 42px !important;
    font-size: 14px;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: 40px;
      height: 40px;
    }

    color: var(--color-text-primary);

    &__today {
      color: var(--color-text-brand);
    }

    &:hover::before {
      background-color: var(--color-bg-light);
    }

    &:not(.CalendarDay__hovered_span):not(.CalendarDay__selected_start):not(.CalendarDay__selected):not(.CalendarDay__selected_end):not(.CalendarDay__selected_span):hover::before {
      border-radius: 50%;
    }

    &__button::after {
      position: absolute;
      top: 1px;
      right: -1px;
      width: 2px;
      height: calc(100% - 2px);
      content: none;
      background-color: var(--color-bg-brand);
    }
  }

  .CalendarDay__button {
    position: relative;
    height: 100%;
    width: 100%;
    text-align: center;
    background: none;
    border: none;
    padding: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;

    @mixin focus-border var(--color-text-primary), -2px {
      z-index: 1;
    }
  }

  .CalendarDay__button:active {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      position: relative;
      top: -1px;
      left: -1px;
    }
  }

  .CalendarDay__highlighted_calendar {
    position: relative;

    &::after {
      position: absolute;
      bottom: 4px;
      left: 18px;
      width: 6px;
      height: 6px;
      content: '';
      border-radius: 50%;
      background-color: var(--color-bg-brand);
    }
  }

  .CalendarDay__hovered_span::before,
  .CalendarDay__selected::before,
  .CalendarDay__selected_span::before {
    border-radius: 50%;
  }

  .CalendarDay__hovered_span::before,
  .CalendarDay__after_hovered_start::before {
    background-color: var(--color-bg-light);
  }

  .CalendarDay__selected_start::before,
  .CalendarDay__selected_end::before,
  .CalendarDay__selected_span::before {
    border-radius: 0;
  }

  .CalendarDay__selected_start::before,
  .CalendarDay__selected_start.CalendarDay__hovered::before,
  .CalendarDay__selected_start.CalendarDay__hovered_span::before {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  .CalendarDay__selected_start.CalendarDay__hovered_span > button::after {
    display: none;
  }

  .CalendarDay__selected_start,
  .CalendarDay__selected_span {
    .CalendarDay__button::after {
      content: '';
    }
  }

  .CalendarDay__selected_end {
    .CalendarDay__button::after {
      content: none;
    }
  }

  .CalendarDay__selected_end::before,
  .CalendarDay__selected_end.CalendarDay__hovered::before,
  .CalendarDay__selected_end.CalendarDay__hovered_span::before {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .CalendarDay__selected,
  .CalendarDay__selected_start,
  .CalendarDay__selected_end,
  .CalendarDay__selected_span {
    color: var(--color-text-active);

    &::before {
      background-color: var(--color-bg-brand);
    }

    &:hover::before {
      background-color: var(--color-bg-brand-hover);
    }

    &:active::before {
      background-color: var(--color-bg-brand-active);
    }
  }

  .CalendarDay__blocked_calendar {
    color: var(--color-text-secondary);
    pointer-events: none;

    &::before {
      background-color: transparent;
    }

    .CalendarDay__button {
      cursor: default;
    }

    .CalendarDay__button::after {
      content: none;
    }
  }

  .CalendarDay__selected_start.CalendarDay__highlighted_calendar::after,
  .CalendarDay__selected_end.CalendarDay__highlighted_calendar::after,
  .CalendarDay__selected_span.CalendarDay__highlighted_calendar::after {
    left: 17px;
    border: 1px solid var(--color-bg-brand);
    background-color: var(--color-bg-default);
  }

  .DayPicker__focus-region div:last-child {
    height: 1px;
  }
}
