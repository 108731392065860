.autosuggest {
  position: relative;
  display: block;
}

.autosuggestMenu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border-top: none;
  z-index: 3;
}

.description {
  display: block;

  @media (--bp-mobile) {
    white-space: normal;
  }

  @media (--bp-mobile-up) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.project {
  margin-top: 4px;
  font-size: 12px;
  color: var(--color-text-secondary);
}
