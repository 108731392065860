.root {
  position: relative;
  padding-left: 20px;
  padding-top: 14px;
  padding-right: 32px;
  padding-bottom: 14px;
  display: flex;
  background-color: var(--color-bg-default);
  border: 1px solid var(--color-bg-stripe);
  border-left-width: 4px;
  box-shadow: var(--shadow-light);
}

.type {
  &_success {
    border-left-color: var(--color-bg-success);
  }

  &_warning {
    border-left-color: var(--color-bg-caution);
  }

  &_error {
    border-left-color: var(--color-bg-alert);
  }

  &_info {
    border-left-color: var(--color-bg-brand);
  }
}

.container {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.content {
  color: var(--color-text-primary);
}

.close {
  position: absolute;
  top: 4px;
  right: 4px;
}
