.notification-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 2;
  overflow: hidden;

  &__empty {
    pointer-events: none;
  }
}

@media (--bp-mobile) {
  .notification-container {
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
  }

  .notification-animation-block-enter {
    margin-bottom: -50px;
    opacity: 0.1;
  }

  .notification-animation-block-enter-active {
    margin-bottom: 0;
    opacity: 1;
    transition: margin 350ms ease-in, opacity 350ms ease-in;
  }

  .notification-animation-block-exit {
    margin-left: 0;
    opacity: 1;
  }

  .notification-animation-block-exit-active {
    margin-left: 50px;
    opacity: 0;
    transition: margin 350ms ease-out, opacity 350ms ease-out;
  }
}

@media (--bp-mobile-up) {
  .notification-container {
    top: 0;
    right: 0;
    padding: 20px 20px 20px 0;
    width: 270px;
  }

  .notification-animation-block-enter {
    margin-top: -50px;
    opacity: 0.1;
  }

  .notification-animation-block-enter-active {
    margin-top: 0;
    opacity: 1;
    transition: margin 350ms ease-in, opacity 350ms ease-in;
  }

  .notification-animation-block-exit {
    margin-left: 0;
    opacity: 1;
  }

  .notification-animation-block-exit-active {
    margin-left: 50px;
    opacity: 0;
    transition: margin 350ms ease-out, opacity 350ms ease-out;
  }
}
