@media (--bp-mobile) {
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .combine {
    margin-bottom: 16px;
  }
}

@media (--bp-mobile-up) {
  .form {
    position: relative;
  }

  .combine {
    position: absolute;
    right: 0;
    bottom: 100%;
    transform: translateY(-12px);
  }
}
