.backLink {
  transform: translateY(-2px);
}

.userMoreActions {
  display: inline-block;
  margin-left: 16px;
  line-height: 0;
}

@media (--bp-mobile) {
  .title {
    margin-bottom: 16px;
  }

  .actions {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .createRateButton {
    display: none;
  }

  .userMoreActions {
    display: none;
  }
}

@media (--bp-mobile-up) {
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .archive {
    margin-top: 32px;
  }
}
