.root {
  height: 100%;
  background-color: var(--color-bg-default);
  cursor: inherit;
}

.softShadow {
  box-shadow: var(--shadow-light);
}

.deepShadow {
  box-shadow: var(--shadow-modal);
}
