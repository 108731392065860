.label {
  display: block;
  padding: 2px;
  width: max-content;
  cursor: pointer;
}

.input {
  @mixin visually-hidden;
}

.inner {
  width: 25px;
  height: 25px;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  box-shadow: 0 0 0 2px;
  pointer-events: none;
  color: var(--color-text-secondary);

  .label:hover & {
    color: var(--color-text-primary);
  }

  &.checked {
    color: var(--color-text-alert);

    .label:hover & {
      color: var(--color-text-alert-hover);
    }
  }

  &.theme_contrast:not(.checked) {
    color: var(--color-text-primary);
    opacity: 0.6;

    .label:hover & {
      opacity: 1;
    }
  }

  @media (--bp-mobile) {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }
}

.input:global(.focus-ring) + .inner {
  @mixin absolute-border currentColor, -4px;

  &.checked {
    color: var(--color-text-alert-hover);
  }

  &:not(.checked) {
    color: var(--color-text-primary);

    &.theme_contrast {
      color: var(--color-text-primary);
      opacity: 1;
    }
  }
}
