.workspace {
  max-width: 568px;

  @media (--bp-mobile-up) {
    margin-top: -8px;
  }
}

.section {
  display: block;
  margin-bottom: 24px;
}

.separator {
  height: 1px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 25px;
  background-color: var(--color-bg-stripe);
}
