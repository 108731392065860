.control {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  background-color: var(--color-bg-component);
  border: 1px solid var(--color-bg-stripe);
  transition: border-color 300ms;

  &_active {
    border-color: var(--color-bg-brand);
  }

  &_disabled {
    background-color: var(--color-bg-component-disabled);
  }

  &_success {
    border-color: var(--color-bg-success);
  }

  &_error {
    border-color: var(--color-bg-alert);
  }

  &_focused {
    border-color: var(--color-bg-brand);
  }
}

.valueContainer {
  flex-grow: 1;
  overflow: hidden;
  padding-left: 15px;
  height: 100%;
}

.valueWrapper {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.value,
.multiOptionValue {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 16px;
  color: var(--color-text-primary);
  font-size: 14px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.placeholder {
  position: absolute;
  top: 15px;
  left: 16px;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-text-secondary);
  cursor: text;
  pointer-events: none;
  user-select: none;
}

.control_focused .placeholder,
.control_hasValue .placeholder {
  top: 7px;
  font-size: 10px;
  line-height: 12px;
}

.control_disabled .placeholder,
.control_disabled .value {
  color: var(--color-text-secondary);
}

.input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 47px;
  padding-top: 17px;
  background-color: transparent;
  border: none;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-text-primary);
  pointer-events: none;
  outline: none;

  @media (--bp-mobile) {
    font-size: 16px;
  }
}

.clear {
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @mixin color-secondary-states;
  @mixin focus-border;
}

.arrow {
  width: 26px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;

  @mixin color-secondary-states;
}

.control_focused .arrow {
  color: var(--color-text-brand);
}

.select .dropdown {
  margin-top: -1px;
  border: 1px solid var(--color-bg-stripe);
  border-radius: 0;
  box-shadow: var(--shadow-light);
}

.dropdownContent {
  background-color: var(--color-bg-default);
}

.select .menu {
  border: none;
}

.select {
  &_isHideInputCaret .input {
    caret-color: transparent;
  }
}

.optionContent {
  display: block;
  overflow: hidden;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: inherit;
}

.noResults {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  color: var(--color-text-secondary);
}

.hintContainer {
  display: block;
}

.hintWithPredefinedStyle {
  position: absolute;
  font-size: 12px;
  margin-top: 2px;
}

.hintStyle {
  &_grey {
    color: var(--color-text-secondary);
  }

  &_success {
    color: var(--color-text-success);
  }

  &_error {
    color: var(--color-text-alert);
  }
}
