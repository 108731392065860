.row {
  display: block;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.forgotPassword {
  margin-bottom: 24px;
  font-size: 14px;
  text-align: right;
}
