.root {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.disabled {
  color: var(--color-text-disabled);
  pointer-events: none;
}

.input {
  @mixin visually-hidden;
}

.switcher {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 24px;
  background-color: var(--color-text-disabled);
  border-radius: 100px;
  transition: background 0.15s ease-out;

  &::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: var(--color-text-active);
    transition: transform 0.2s ease-out;
  }

  &_checked {
    background-color: var(--color-bg-brand);

    &::after {
      transform: translateX(23px);
    }
  }

  .root:hover & {
    background-color: var(--color-text-secondary);

    &_checked {
      background-color: var(--color-bg-brand-hover);
    }
  }

  .input:global(.focus-ring) + & {
    background-color: var(--color-text-secondary);

    @mixin absolute-border var(--color-bg-stripe-hover), -2px;

    &_checked {
      background-color: var(--color-bg-brand-hover);

      @mixin absolute-border var(--color-bg-brand-hover), -2px;
    }
  }

  .input:active + &,
  .root:active & {
    background-color: var(--color-text-primary);

    :--dark-theme {
      background-color: var(--color-text-secondary);
    }

    &_checked {
      background-color: var(--color-bg-brand-active);

      :--dark-theme {
        background-color: var(--color-bg-brand-active);
      }
    }
  }

  .input:disabled + & {
    background-color: var(--color-text-disabled);

    &::after {
      background-color: var(--color-text-disabled);
    }

    &_checked {
      background-color: var(--color-bg-brand-disabled);

      &::after {
        background-color: var(--color-text-active);
      }
    }
  }
}

.label {
  display: block;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  line-height: 19px;

  .textPosition_right & {
    margin-left: 8px;
  }

  .textPosition_left & {
    order: -1;
    margin-right: 8px;
  }
}
