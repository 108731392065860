.wrapper {
  position: relative;

  @media (--bp-mobile) {
    margin-bottom: 16px;
  }

  @media (--bp-mobile-up) {
    margin-bottom: 32px;
  }

  @media (min-width: 1228px) {
    margin-bottom: 62px;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    background-color: var(--color-bg-stripe);

    @media (--bp-mobile) {
      left: -16px;
      right: -16px;
    }

    @media (--bp-mobile-up) {
      left: -48px;
      right: -48px;
    }
  }
}
