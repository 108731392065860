.root {
  position: relative;
  height: 100%;
  color: var(--color-text-primary);

  :--dark-theme {
    border: 1px solid var(--color-bg-stripe);
  }
}

.title {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 24px;
  font-weight: 300;
  border-bottom: 1px solid var(--color-bg-stripe);
  word-wrap: break-word;

  @media (--bp-mobile) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (--bp-mobile-up) {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.content {
  height: calc(100% - 69px);
  font-size: 14px;

  &_paddings {
    padding: 16px;

    @media (--bp-mobile-up) {
      padding-top: 16px;
      padding-bottom: 32px;
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  @media (--bp-mobile-up) {
    &_small {
      width: 350px;
    }

    &_medium {
      width: 500px;
    }

    &_large {
      width: 650px;
    }
  }
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  width: 100%;
  margin-top: 16px;
}

.actionItem:first-child {
  margin-right: -8px;
}
